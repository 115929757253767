import { ErpTableOptions } from '@/types/type';

import { defineComponent, ref } from 'vue';
export default () => {
  const tableRef = ref();
  const isSync = [
    {
      value: true,
      label: '是',
    },
    {
      value: false,
      label: '否',
    },
  ];
  const columnList: ErpTableOptions[] = [
    {
      label: '销售合同号',
      prop: 'businessContractNo', // 属性
      minWidth: 166,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },
    {
      label: '客户',
      prop: 'customerName', // 属性
      minWidth: 206,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },
    {
      label: '费用类型',
      prop: 'feeType',
      propDesc: 'feeTypeDesc',
      minWidth: 130,
      value: [],
      type: 'multiSelect', // 筛选类型 input 输入项
      options: 'ar_fee_type',
    },
    {
      label: '日期',
      prop: 'arPayDate',
      minWidth: 110,
      value: [],
      type: 'time', // 筛选类型 time 日期
      filterProp: ['arPayDateStartDate', 'arPayDateEndDate'],
    },
    {
      label: '金额(人民币)',
      prop: 'amountRmb', // actualWeightSum
      propDesc: 'amountRmbDesc',
      minWidth: 150,
      value: [],
      type: 'number',
      filterProp: ['amountRmbMinVal', 'amountRmbMaxVal'],
    },
    {
      label: '金额(交易币种)',
      prop: 'arAmountTc', // actualWeightSum
      propDesc: 'arAmountTcDesc',
      minWidth: 170,
      value: [],
      type: 'number',
      filterProp: ['arAmountTcMinVal', 'arAmountTcMaxVal'],
    },
    {
      label: '汇率',
      prop: 'arExchangeRate', // actualWeightSum
      minWidth: 120,
      value: [],
      type: 'number',
      filterProp: ['arExchangeRateMinVal', 'arExchangeRateMaxVal'],
    },
    {
      label: '修改后金额', // to-do修改后金额
      prop: 'amountRmbAfterModify', // to-do修改后金额
      propDesc: 'amountRmbAfterModifyDesc', // to-do修改后金额
      minWidth: 160,
      value: [],
      type: 'number',
      filterProp: ['amountRmbAfterModifyMaxVal', 'amountRmbAfterModifyMaxVal'],
    },
    {
      label: '备注',
      prop: 'remark',
      minWidth: 206,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },
    {
      label: '凭据文件',
      prop: '',
      minWidth: 90,
      value: '',
      type: 'input', // 筛选类型 input 输入项
      isSort: false,
      isFilter: false,
      component: defineComponent({
        template: `
        <tableFileContent :readOnly="true"
        :fileUrlsArray="row.fileVoList"
        key-name="orderArapFileName"
        key-url="orderArapFileUrl"
        key-id="orderArapFileId"
      ></tableFileContent>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup() {
          return {};
        },
      }),
    },
    {
      label: '是否同步到应付',
      prop: 'isSyncToAp',
      propDesc: 'isSyncToApDesc',
      type: 'signSelect',
      options: isSync,

      value: '',

      minWidth: 170,
    },
    {
      label: '审核时间',
      prop: 'auditTime',
      minWidth: 180,
      value: [],
      type: 'time',
      filterProp: ['auditTimeStartDate', 'auditTimeEndDate'],
    },
    {
      label: '审核人',
      prop: 'auditOperator',
      minWidth: 130,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },

    {
      label: '审核说明',
      prop: 'auditDesc',
      minWidth: 206,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },
    {
      label: '删除人', // to-do
      prop: 'lastOperator', // to-do
      minWidth: 120,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },
    {
      label: '删除时间',
      prop: 'updateTime', // to-do
      minWidth: 180,
      value: [],
      type: 'time', // 筛选类型 time 日期
      filterProp: ['updateTimeStartDate', 'updateTimeEndDate'],
    },
  ];
  return { columnList, tableRef };
};
