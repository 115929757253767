
import { defineComponent } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './data/useDelArAuditList';
import { erpConfirm, errorMessage, exportFile } from '@/utils/publicMethods';
import useTimer from '@/hooks/useTimer';
import router from '@/router';
export default defineComponent({
  name: 'delArAuditList',
  components: { ErpList },
  props: {},
  setup() {
    const { columnList, tableRef } = useListData();
    const { startTimer, timeStatus } = useTimer();
    // 刷新列表
    const back = () => {
      router.push('/erp/financial/accrued');
    };
    const exportFileFn = () => {
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/orderArap/exportOrderArDeleteList',
            data: tableRef.value.getQueryParams(),
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };

    return {
      exportFileFn,
      back,
      columnList,
      tableRef,
    };
  },
});
